<template>
    <div class="box" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)">

        <div class="teb_header">
            <el-button icon="el-icon-plus" :size='tabsize' @click='addTo'>添加</el-button>
            <el-button icon="el-icon-delete" :size='tabsize' @click='delAll'>删除</el-button>
            <el-input :size='tabsize' v-model='input' placeholder="请输入内容">
                <el-button :size='tabsize' slot="append" icon="el-icon-search"></el-button>
            </el-input>
        </div>

        <el-table :data="tableData" style="width: 100%;height:calc(100% - 90px);" stripe :size='tabsize'
            @sort-change='sortchange' :default-sort="{prop: 'date', order: 'descending'}" @selection-change="selection"
            border>
            <el-table-column type='selection' width='50'>
            </el-table-column>
            <el-table-column label="编号" prop='id' align='center'>
                <template slot-scope="scope">
                    <span>{{ scope.row.id }}</span>
                </template>
            </el-table-column>
            <el-table-column label="角色" prop='cname' align='center'>
                <template slot-scope="scope">
                    <span>{{ scope.row.cname }}</span>
                </template>
            </el-table-column>
            <el-table-column label="备注" prop='remark' align='center'>
                <template slot-scope="scope">
                    <span>{{ scope.row.remark }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align='center'>
                <template slot-scope="scope">
                    <el-button :size="tabsize" icon='el-icon-edit' @click="handleEdit(scope.$index, scope.row)">编辑
                    </el-button>
                    <el-button :size="tabsize" type="danger" icon='el-icon-delete'
                        @click="handleDelete(scope.$index, scope.row);dialogVisible = true">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>


        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length">
        </el-pagination>

        <!-- 表单 -->
        <el-dialog :title="titleTxt" width='600px' :close-on-click-modal="false" :visible.sync="formShow"
            v-if="formShow">
            <forms :formInfo='formInfo'></forms>
        </el-dialog>

        <el-dialog title="提示" :close-on-click-modal="false" :visible.sync="dialogVisible" width="30%">
            <span>确定要删除吗</span>
            <span slot="footer" class="dialog-footer">
                <el-button :size='tabsize' @click="dialogVisible = false">取 消</el-button>
                <el-button :size='tabsize' type="primary" @click="dialogVisible = false; del()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import forms from './form.vue'
    export default {
        components: {
            forms
        },
        data() {
            return {
                input: '',
                tableData: [],
                currentPage: 1, //默认显示第一页
                pageSize: 10, //默认每页显示10条
                totalNum: '', //总页数
                ids: [],
                formShow: false,
                formInfo: {},
                titleTxt: '新增',
                loading: true,
                dialogVisible: false,
                thisid: '',
                order: '',
                sort: '',
                menuData: [],
            }
        },
        methods: {
            updateList() {
                this.$api.role.updateList({
                    'jPage.pageNum': this.currentPage,
                    'jPage.pageSize': this.pageSize,
                    'jPage.sort': this.sort,
                    'jPage.order': this.order
                }).then(res => {
                    this.tableData = res.obj.list //获取数据列表
                    this.totalNum = res.obj.totalPage // 总页数
                    this.tableData.length = res.obj.totalRow //总条数
                    this.currentPage = res.obj.pageNumber //当前页码数
                    this.loading = false
                }).catch((error) => {
                    console.log(error);
                })
            },
            handleEdit(index, row) {
                this.titleTxt = '编辑'
                this.loading = true
                this.$api.role.get({
                    id: row.id
                }).then(res => {
                    this.formInfo = res.obj
                    this.formShow = true
                    this.loading = false
                }).catch((error) => {
                    console.log(error);
                })
            },
            // 分页大小
            handleSizeChange(val) {
                this.loading = true
                this.pageSize = val
                this.currentPage = 1 // 选择分页大小后跳转第一页
                this.updateList()
            },
            // 第几页
            handleCurrentChange(val) {
                this.loading = true
                this.currentPage = val
                this.updateList()
            },
            // 多选
            selection(selection) {
                this.ids = []
                for (let i = 0; i < selection.length; i++) {
                    this.ids.push(selection[i].id)
                }
            },
            // 删除选中
            delAll() {
                console.log(this.ids);
                if (this.ids.length > 0) {
                    this.thisid = this.ids.join(',')
                    this.dialogVisible = true
                }
            },
            // 新增
            addTo() {
                this.formShow = true
                this.titleTxt = '新增'
                this.formInfo = {}
            },
            // 删除
            handleDelete(index, row) {
                this.thisid = row.id
            },
            // 删除确认
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            // 删除
            del() {
                this.loading = true
                let data = {
                    ids: this.thisid
                }
                this.$api.role.del(data)
                    .then(res => {
                        console.log(res);
                        if (res.status == 1) {
                            this.$notify.success({
                                title: 'success',
                                message: '删除成功',
                                showClose: false,
                                duration: 2000,
                            });
                            this.updateList()
                        } else if (res.status == 0) {
                            this.$notify.error({
                                title: 'error',
                                message: res.msg,
                                showClose: false,
                                duration: 2000,
                            });
                            this.loading = false
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.loading = false
                    })
            },
            // 排序
            sortchange(val) {
                this.sort = val.prop
                if (val.order === 'descending') {
                    this.order = 'desc'
                } else if (val.order === 'ascending') {
                    this.order = 'asc'
                } else {
                    this.order = ''
                    this.sort = ''
                }
                this.loading = true
                this.updateList()
            }
        },
        // 监听尺寸按钮变化 改变表格大小
        computed: {
            tabsize() {
                return this.$store.state.tabsize
            }
        },
        created() {
            this.updateList()
            this.totalNum = this.tableData.length;
            // 获取功能数据
            this.$api.menu.updateList().then(res => {
                this.menuData = this.$table2Tree(res.obj)
            })
        },
    }
</script>

<style scoped>
    .box/deep/.el-table th.gutter {
        display: table-cell !important;
    }

    .box {
        position: relative;
        width: 98%;
        height: 98%;
        margin: 1%;
    }

    .teb_header {
        /* height: 30px;
        line-height: 30px; */
        margin-bottom: 10px;
    }

    .teb_header .el-input {
        width: auto;
        margin-left: 20px;
    }

    .el-table td {
        padding: 5px 0;
    }

    .el-pagination {
        float: right;
        margin-top: 10px;
    }

    .el-dialog__wrapper/deep/.el-dialog__header {
        text-align: center;
    }

    /deep/.el-table__body-wrapper {
        height: 98%;
        overflow-y: auto;
    }
</style>