<template>
    <div>
        <el-form label-position="right" label-width="80px" ref='formInfo' :rules='rules' inline :model="formInfo"
            :size='tabsize'>

            <el-row>
                <el-col :span='12'>
                    <el-form-item label="角色名称" prop='cname'>
                        <el-input v-model="formInfo.cname"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label="菜单权限">
                        <el-input v-model='rolemenu' @focus=" innerVisible = true " suffix-icon="el-icon-edit"></el-input>
                        <el-dialog width="30%" title="选择功能模块" :visible.sync="innerVisible" append-to-body>
                            <el-form>
                                <el-tree :data="menu" node-key="id" ref="tree" highlight-current multiple show-checkbox
                                    :props="defaultProps" :default-checked-keys='rmsarr'>
                                </el-tree>
                                <p class="btns">
                                    <el-button :size='tabsize' type="primary" @click="choosed();innerVisible = false">提交
                                    </el-button>
                                </p>
                            </el-form>
                        </el-dialog>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item label="备注" class="remark">
                    <el-input v-model="formInfo.remark"></el-input>
                </el-form-item>
            </el-row>
            <p class="btns">
                <el-button type="primary" :size='tabsize' @click="submitForm('formInfo')">提交</el-button>
            </p>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                innerVisible: false,
                defaultProps: {
                    children: 'children',
                    label: 'cname'
                },
                menu: [],
                rmsarr: [],
                options: [{
                        value: 1,
                        label: '正常'
                    },
                    {
                        value: 0,
                        label: '停用'
                    }
                ],
                rules: {
                    cname: [{
                        required: true,
                        message: '请输入部门名称',
                        trigger: 'blur'
                    }],
                },
                rolemenu: ''
            }
        },
        props: ['formInfo'],

        mounted() {
            this.menu = this.$parent.$parent.menuData
            console.log(this.menu);

            if (this.formInfo.rms) {
                let rms = this.formInfo.rms
                this.rmsarr = rms.split(',')
            }
            JSON.parse(localStorage.getItem('menuArr')).map((item) => {
                if (this.rmsarr.length > 1) {
                    for (let i = 0; i < this.rmsarr.length; i++) {
                        if (item.id == this.rmsarr[i]) {
                            this.rolemenu += item.cname + ','
                        }
                    }
                }
            })
            // this.rolemenu = 
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$parent.$parent.loading = true
                        this.$parent.$parent.formShow = false
                        let url
                        let str
                        if (this.formInfo.id) {
                            url = this.$api.role.edit
                            str = '修改'
                        } else {
                            url = this.$api.role.add
                            str = '添加'
                        }
                        url(this.formInfo).then(res => {
                            this.$parent.$parent.loading = false
                            this.$parent.$parent.updateList()
                            if (res.status == 1) {
                                this.$notify.success({
                                    title: 'Info',
                                    message: str + '成功',
                                    showClose: false,
                                    duration: 2000,
                                });
                            } else {
                                this.$notify.error({
                                    title: 'Info',
                                    message: res.msg,
                                    showClose: false,
                                    duration: 2000,
                                });
                            }
                        }).catch((error) => {
                            console.log(error);
                        })
                    } else {
                        return false;
                    }
                })
            },
            choosed() {
                let arr = this.$refs.tree.getCheckedKeys()
                let arr1 = this.$refs.tree.getHalfCheckedKeys()
                console.log("menuArr==>",localStorage.getItem('menuArr'))
                JSON.parse(localStorage.getItem('menuArr')).map((item) => {
                    if (arr.length > 1) {
                        for (let i = 0; i < arr.length; i++) {
                            if (item.id == arr[i]) {
                                this.rolemenu += item.cname + ','
                            }
                        }
                    }
                })
                arr = arr.join(',')
                arr1 = arr1.join(',')
                this.formInfo.rms = arr
                this.formInfo.rmsx = arr1
            },
            rolechange() {}
        },
        // 监听尺寸按钮变化 改变表格大小
        computed: {
            tabsize() {
                return this.$store.state.tabsize
            }
        },
    }
</script>

<style scoped>
    .btns {
        text-align: center;
    }

    .el-tree {
        height: 400px;
        overflow-y: auto;
    }

    .btn {
        /* position: absolute;
        bottom: 0; */
        margin-top: 30px;
        margin-bottom: 0 !important;
    }

    /deep/.el-form-item__content {
        width: 190px;
    }

    .remark{
        width: 100%;
    }
    .remark/deep/.el-form-item__content {
        width: calc(100% - 92px);
    }
</style>